/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.auth-wrapper {
  .auth-inner {
    .brand-logo {
      align-items: center;
      .brand-text {
        margin-bottom: 0;
      }
      img {
        border-radius: 5px;
      }
    }
    .card {
      .card-title {
        text-align: center;
      }
    }
  }
}
.navigation li a i,
.navigation li a svg {
  height: 22px;
  width: 22px;
}
.main-menu {
  .navigation {
    margin-top: 50px;
  }
  .navbar-header {
    .nav {
      .brand-logo img {
        border-radius: 5px;
      }
    }
  }
}
.\!mb-1 {
  margin-bottom: 1rem !important;
}
svg.folder-yellow {
  fill: #ffca28;
}
svg.folder-blue {
  fill: #52c2f8;
}
.flex-center {
  justify-content: center;
  align-items: center;
}

.dropzone {
  display: flex;
  cursor: pointer;
  min-height: 300px;
  align-items: "center";
  justify-content: center;
  background-color: #161d31;
  border: 1px dashed #404656;
  border-radius: 0.357rem;
}
.dropzone.is-invalid {
  border-color: #ea5455;
}
.rc-tooltip-inner {
  padding: 0.4rem 0.775rem !important;
  text-align: center !important;
  background-color: #323232 !important;
  border-radius: 0.358rem !important;
  color: white !important;
  border: none !important;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #323232 !important;
}
.form-control[readonly] {
  opacity: 0.8;
}
.text-right {
  text-align: right;
}
.avatar-stats {
  cursor: default !important;
}
.flatpickr-weekdays,
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
span.flatpickr-weekday,
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #bb2649 !important;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #bb2649 !important;
  box-shadow: -10px 0 0 #bb2649 !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #bb2649 !important;
  border-color: #bb2649 !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #bb2649 !important;
}
.flatpickr-input {
  color: white !important;
  opacity: 0.8 !important;
}
.dark-layout .select__control {
  background-color: #283046;
  border-color: #3b4253;
}
.select__single-value,
span.flatpickr-weekday {
  color: white !important;
}
